export default [
  {
    key: 'imageId',
    initKey: 'image',
    label: 'field.image',
    type: 'async-single-image',
    path: 'product-category',
    rules: '',
    pw: 200,
    ph: 200,
    fullWidth: false,
    cols: 12,
  },
  {
    key: 'name',
    label: 'field.name',
    rules: 'required|max:200',
    type: 'text',
  },
  {
    key: 'idCard',
    label: 'field.idCard',
    rules: 'required|max:20',
    type: 'text',
  },
  {
    key: 'phoneNumber',
    label: 'field.phoneNumber',
    rules: 'max:200',
    type: 'tel',
  },
  {
    key: 'dailySalesTargetAmount',
    label: 'field.dailySalesTargetAmount',
    rules: 'required|decimal:2',
    type: 'currency',
    suffix: 'CTN',
  },
  {
    key: 'weeklySalesTargetAmount',
    label: 'field.weeklySalesTargetAmount',
    rules: 'required|decimal:2',
    type: 'currency',
    suffix: 'CTN',
  },
  {
    key: 'monthlySalesTargetAmount',
    label: 'field.monthlySalesTargetAmount',
    rules: 'required|decimal:2',
    type: 'currency',
    suffix: 'CTN',
  },
  {
    key: 'yearlySalesTargetAmount',
    label: 'field.yearlySalesTargetAmount',
    rules: 'required|decimal:2',
    type: 'currency',
    suffix: 'CTN',
  },
  {
    label: 'breadcrumb.workingInformation',
    icon: 'BriefcaseIcon',
    type: 'divider',
  },
  {
    key: 'managerId',
    label: 'field.manager',
    type: 'async-single-selection',
    repository: 'salesperson',
    selectionKey: 'id',
    selectionLabel: 'label',
    clearable: true,
  },
  {
    key: 'regionId',
    label: 'field.region',
    rules: 'required',
    type: 'async-single-selection',
    repository: 'region',
    selectionKey: 'id',
    selectionLabel: 'regionCode',
  },
  {
    key: 'departmentId',
    label: 'field.department',
    rules: 'required',
    type: 'async-single-selection',
    repository: 'department',
    selectionKey: 'id',
    selectionLabel: 'name',
    localization: true,
  },
  {
    key: 'positionId',
    label: 'field.position',
    rules: 'required',
    type: 'async-single-selection',
    repository: 'position',
    selectionKey: 'id',
    selectionLabel: 'name',
    localization: true,
  },
  {
    key: 'workingScheduleId',
    label: 'field.workingSchedule',
    rules: 'required',
    type: 'async-single-selection',
    repository: 'workingSchedule',
    selectionKey: 'id',
    selectionLabel: 'name',
    localization: true,
  },
  {
    label: 'breadcrumb.leaveRequest',
    icon: 'CalendarIcon',
    type: 'divider',
  },
  {
    key: 'firstLeaveApprovalId',
    label: 'field.firstApproval',
    type: 'async-single-selection',
    repository: 'salesperson',
    selectionKey: 'id',
    selectionLabel: 'label',
    clearable: true,
  },
  {
    key: 'secondLeaveApprovalId',
    label: 'field.secondApproval',
    type: 'async-single-selection',
    repository: 'salesperson',
    selectionKey: 'id',
    selectionLabel: 'label',
    clearable: true,
  },
  {
    key: 'leaveVerifyId',
    label: 'field.verify',
    rules: 'required',
    type: 'async-single-selection',
    repository: 'user',
    selectionKey: 'id',
    selectionLabel: 'name',
  },
  {
    key: 'finalLeaveApprovalId',
    label: 'field.finalApproval',
    rules: 'required',
    type: 'async-single-selection',
    repository: 'user',
    selectionKey: 'id',
    selectionLabel: 'name',
  },
  {
    label: 'breadcrumb.accountInfomration',
    icon: 'LockIcon',
    type: 'divider',
  },
  {
    key: 'username',
    label: 'field.username',
    rules: 'required',
    type: 'tel',
    cols: 6,
  },
  {
    hide: true,
    skip: true,
    cols: 6,
  },
  {
    key: 'password',
    name: 'new-password',
    label: 'field.password',
    rules: 'required|min:6',
    type: 'password',
    hideInView: true,
  },
  {
    key: 'retypePassword',
    label: 'field.retypePassword',
    rules: 'required|confirmed:password',
    type: 'password',
    hideInView: true,
  },
  {
    key: 'dpVisible',
    label: 'field.dpVisible',
    rules: '',
    type: 'checkbox',
    cols: 'auto',
  },
  {
    key: 'isEnable',
    label: 'field.active',
    rules: '',
    type: 'checkbox',
    cols: 'auto',
  },
]
